import type { AppProps } from 'next/app';
import Head from 'next/head';

import { init } from '@app/services/sentry';
import 'antd/dist/antd.css';
import '@app/styles/global.css';
import { AuthProvider } from '@app/context/useAuth';

init();

export default function App(props: AppProps): JSX.Element {
  const { pageProps, Component } = props;
  // eslint-disable-next-line prefer-destructuring
  const err = (props as AppProps & { err: unknown }).err;

  return (
    <AuthProvider>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <Component {...pageProps} err={err} />
    </AuthProvider>
  );
}

import * as Sentry from '@sentry/node';
import { RewriteFrames } from '@sentry/integrations';

export const init = (): void => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = [];
    const sentryRootDir = process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR;
    if (process.env.NEXT_IS_SERVER === 'true' && sentryRootDir) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: (frame) => {
            const newFrame = frame;
            newFrame.filename = newFrame.filename?.replace(sentryRootDir, 'app:///');
            newFrame.filename = newFrame.filename?.replace('.next', '_next');
            return newFrame;
          },
        }),
      );
    }

    Sentry.init({
      enabled: true,
      environment: process.env.NODE_ENV,
      integrations,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      release: process.env.NEXT_PUBLIC_COMMIT_SHA,
    });
  }
};
